<template>
  <!--begin::Service Listing-->
  <div class="service-template">
    <ListingTemplate
      :customClass="'service-listing'"
      v-if="getPermission('service:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows && selectedRows.length > 0">
              <v-col cols="4" class="pt-0">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('service:update')">
                  <!--   <v-menu
                    content-class="custom-menu-list"
                    left
                    offset-y
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Action
                      </v-btn>
                    </template>
<v-list>

  <template v-for="(item, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="productBulkAction(item.status)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              item.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ item.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
</v-list>
</v-menu> -->
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-col>
            </template>

            <template v-else>
              <v-col cols="5" class="pt-0">
                <v-layout class="w-100">
                  <!--  <v-flex md6>
                      <h1 class="form-title d-flex margin-auto">
                        <v-select
                          :disabled="dataLoading"
                          :items="statusList"
                          v-model="status"
                          hide-details
                          item-color="cyan"
                          class="pt-0 mt-0 listing-status main-listing-status-filter"
                          item-text="text"
                          item-value="value"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.text"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-text="item.description"
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-chip
                                :color="item.color"
                                :text-color="item.textcolor"
                              >
                                <template v-if="item.value == 'all'">{{
                                  item.all_services
                                }}</template>

<template v-else>{{
                                  item.service_status_count
                                }}</template>
</v-chip>
</v-list-item-action>
</template>
</v-select>
</h1>
</v-flex> -->
                  <!-- <v-flex md6>
                      <h1 class="form-title d-flex margin-auto">
                        <v-select
                          :disabled="dataLoading"
                          :items="categoryList"
                          v-model="category"
                          v-on:change="getRows()"
                          hide-details
                          item-color="cyan"
                          class="pt-0 mt-0 listing-status main-listing-status-filter"
                          item-text="text"
                          item-value="id"
                        >
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.text"
                              ></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-chip color="cyan" text-color="white">
                                <template v-if="!item.id">{{
                                  item.all_services
                                }}</template>
                                <template v-else>{{
                                  item.services_count
                                }}</template>
                              </v-chip>
                            </v-list-item-action>
                          </template>
                        </v-select>
                      </h1>
                    </v-flex> -->
                </v-layout>
              </v-col>
              <v-col cols="7" class="pt-0 justify-flex-end d-flex margin-auto">
                <!-- <template v-if="getPermission('service:create')">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      :to="getDefaultRoute('service.create')"
                      color="cyan white--text"
                    >
                      <v-icon dark left>mdi-plus</v-icon> Create
                    </v-btn>
                  </template> -->
                <!-- <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                    color="cyan white--text"
                    v-on:click="searchDialog = true"
                  >
                    <v-icon dark>mdi-filter</v-icon>
                  </v-btn> -->
                <!-- <v-btn
                    v-if="!isEngineerChannel()"
                    v-on:click="
                      $router.push(
                        getDefaultRoute('profile.setting', {
                          query: {
                            tab: 'service',
                          },
                        })
                      )
                    "
                    :disabled="dataLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                    color="cyan white--text"
                  >
                    <v-icon dark>mdi-cog</v-icon>
                  </v-btn> -->

                <v-flex
                  v-if="!internal || engineerId"
                  md="2"
                  class="pb-2 mr-2 mxw-200"
                >
                  <!-- <v-autocomplete
                    style="width: 300px"
                    hide-details
                    v-model.trim="filter_visit"
                    clearable
                    :items="visitList"
                    dense
                    flat
                    filled
                    label="Visit"
                    item-color="cyan"
                    color="cyan"
                    solo
                    :disabled="pageLoading || customerLoading"
                    :loading="customerLoading || pageLoading"
                    item-text="visit_barcode"
                    item-value="visit_id"
                    v-on:change="getVisits()"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'No Customer(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.visit_barcode }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                  </v-autocomplete> -->
                </v-flex>

                <v-flex
                  v-if="!internal || engineerId"
                  md="2"
                  class="pb-2 mr-2 mxw-200"
                >
                  <v-autocomplete
                    hide-details
                    style="width: 300px"
                    v-model.trim="filter_customer"
                    clearable
                    :items="customerList"
                    dense
                    flat
                    filled
                    label="Customer"
                    item-color="cyan"
                    color="cyan"
                    solo
                    :disabled="pageLoading || customerLoading"
                    :loading="customerLoading || pageLoading"
                    item-text="display_name"
                    item-value="id"
                    v-on:change="getVisits"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'No Customer(s) Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>

                    <template v-slot:item="{ item }" style="width: 300px">
                      <v-list-item-avatar>
                        <v-img
                          :lazy-src="$defaultProfileImage"
                          :src="$assetAPIURL(item.profile_logo)"
                          aspect-ratio="1"
                          class="margin-auto"
                          transition="fade-transition"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.display_name }}</v-list-item-title
                        >
                        <v-list-item-title
                          class="text-capitalize font-weight-500 font-size-16"
                          >{{ item.company_name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        v-if="item.default_person"
                        class="align-self-center"
                      >
                        <v-list-item-subtitle
                          class="text-lowercase pb-2 font-weight-500 font-size-14"
                          >{{
                            item.default_person.primary_email
                          }}</v-list-item-subtitle
                        >
                        <v-list-item-subtitle
                          class="text-lowercase font-weight-500 font-size-14"
                          >{{
                            item.default_person.primary_phone
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-action>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex md="3" class="pb-2 mr-2 mxw-200">
                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        dense
                        filled
                        flat
                        label="Date Range"
                        hide-details
                        solo
                        :loading="pageLoading"
                        :disabled="pageLoading"
                        clearable
                        prepend-inner-icon="mdi-calendar"
                        v-on:click:clear="clearFilter('dates')"
                        style="width: 300px"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="formattedDate"
                        color="cyan"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="cyan"
                      range
                      :disabled="pageLoading"
                      v-on:change="getVisits"
                      v-model="dates"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-menu content-class="custom-menu-list" offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>

                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          v-on:change="updateVisible()"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule=""
          :basicSearchFields="[
            'company_name',
            'time_sheet',
            'service_form',
            'service_barcode',
            'visit_barcode',
            'created_by',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <!-- <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAlls"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox> -->
                      </template>

                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>

            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData && rowData.length > 0">
                  <tr
                    class="service-listing-row"
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.service_form_id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>

                        <template v-else-if="cols.field == 'image'">
                          <v-img
                            class="customer-image"
                            width="70"
                            height="70"
                            contain
                            :lazy-src="$defaultImage"
                            :src="$assetAPIURL(data.service_image)"
                          >
                          </v-img>
                        </template>

                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode
                            :id="data.id"
                            :barcode="
                              data && data.visit_barcode && data.visit_barcode
                            "
                            route="visit.detail"
                          ></Barcode>

                          <p class="m-0 max-content-width text-truncate">
                            <span class="d-block">
                              {{ formatDate(data.started_at) }}
                              {{ formatDateTimeStart(data.started_at) }} -
                              {{ formatetimedata(data.finished_at) }}
                            </span>
                            <span class="d-block">
                              <v-chip
                                class="mt-2 mb-2 font-weight-600 cursor-pointer text-lowercase"
                                color="chip-custom-red"
                                small
                                outlined
                                label
                              >
                                <template v-if="data.status === 4">
                                  completed in {{ data.duration }}
                                </template>

                                <template v-else-if="data.status === 2">
                                  in-progress
                                </template>

                                <template v-else>
                                  <template v-if="data.overdue === 1"
                                    >delayed by
                                    {{
                                      calculateTotalDuration(data.started_at)
                                    }}</template
                                  >

                                  <template v-else-if="data.today === 1"
                                    >ends in
                                    {{
                                      calculateTotalDuration(data.finished_at)
                                    }}</template
                                  >

                                  <template v-else
                                    >starts in
                                    {{
                                      calculateTotalDuration(data.started_at)
                                    }}</template
                                  >
                                </template>
                              </v-chip>
                            </span>
                          </p>
                        </template>

                        <template v-else-if="cols.field == 'service_barcode'">
                          <div class="d-block">
                            <template v-if="data.trip_id">
                              <Barcode
                                :barcode="getBarcodeBytrip(data)"
                              ></Barcode>
                            </template>

                            <template v-else>
                              <Barcode :barcode="getBarcode(data)"></Barcode>
                            </template>
                            <!--  <div>
                              <Barcode :barcode="data.time_sheet"></Barcode>
                            </div> -->
                            <!-- <div>
                              <v-chip
                                class="mt-2 mb-2 font-weight-600 cursor-pointer text-lowercase"
                                color="chip-custom-green"
                                small
                                outlined
                                label
                              >
                                {{ formatDate(data.timeline_date) }}
                                {{ formatetimedata(data.start_at) }} -
                                {{ formatetimedata(data.end_at) }}
                              </v-chip>
                            </div> -->
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'trip'">
                          <div class="d-block">
                            <template v-if="data && data.trip_id">
                              <Barcode :barcode="data.trip_barcode"></Barcode>
                            </template>

                            <template v-else>
                              <em class="text-muted" style="font-size: 16px">
                                no trip</em
                              >
                            </template>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'voice_note'">
                          <v-icon
                            class="mx-2 custom-grey-border custom-bold-button white--text"
                            :disabled="data && data.voice_notes ? false : true"
                            color="green"
                            v-on:click="showNotes(data)"
                            >mdi-play-circle</v-icon
                          >
                          <span
                            class="font-size-16 font-weight-700 red--text"
                            v-if="
                              data &&
                              data.voice_notes &&
                              data.voice_notes.length &&
                              data.voice_notes.length > 1
                            "
                            v-on:click="showNotes(data)"
                          >
                            +{{ data.voice_notes.length - 1 }} More</span
                          >
                        </template>

                        <template v-else-if="cols.field == 'service_info'">
                          <div class="d-block">
                            <div>
                              <Barcode :barcode="data.time_sheet"></Barcode>
                            </div>
                            <!-- <div>
                              <v-chip
                                class="mt-2 mb-2 font-weight-600 cursor-pointer text-lowercase"
                                color="chip-custom-green"
                                small
                                outlined
                                label
                              >
                                {{ formatDate(data.timeline_date) }}
                                {{ formatetimedata(data.start_at) }} -
                                {{ formatetimedata(data.end_at) }}
                              </v-chip>
                            </div> -->
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'customer'">
                          <div class="warranty-listing-product">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              <template v-if="data && data.display_names">{{
                                data.display_names
                              }}</template>

                              <template v-else
                                ><em class="text--secondary"
                                  >No Display Name</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company: </b>

                              <template v-if="data && data.company_name">{{
                                data.company_name
                              }}</template>

                              <template v-else
                                ><em class="text--secondary"
                                  >No Company</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>

                              <template v-if="data && data.company_email">{{
                                data.company_email
                              }}</template>

                              <template v-else
                                ><em class="text--secondary"
                                  >No Email</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>

                              <template v-if="data && data.company_number">{{
                                data.company_number
                              }}</template>

                              <template v-else
                                ><em class="text--secondary"
                                  >No Phone</em
                                ></template
                              >
                            </p>
                          </div>
                          <!--   <p class="m-0 custom-nowrap-ellipsis">
                            <template v-if="data.display_names">
                              <span class="d-block">
                                Display Name: {{ data.display_names }}
                              </span>
                              <span class="d-block">
                                Company Name: {{ data.company_name }}
                              </span>
                            </template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Quotation</em
                              ></template
                            >
                          </p> -->
                        </template>

                        <template v-else-if="cols.field == 'quotation'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <template v-if="data.quotation_barcode">
                              <Barcode
                                :barcode="data.quotation_barcode"
                              ></Barcode>
                              <span class="d-block">
                                Title: {{ data.quotation_job_title }}
                              </span>
                            </template>

                            <template v-else
                              ><em class="text--secondary"
                                >No Quotation</em
                              ></template
                            >
                          </p>
                        </template>

                        <template v-else-if="cols.field == 'price'">
                          <p class="m-0 custom-nowrap-ellipsis text-capitalize">
                            <v-chip
                              class="d-inline custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
                              color="red"
                              text-color="white"
                              label
                              small
                            >
                              <template v-if="data.service_form">
                                {{
                                  data.service_form.replace("-", " ")
                                }}</template
                              >

                              <template v-else
                                ><em class="text--secondary"
                                  >No form</em
                                ></template
                              >
                            </v-chip>
                          </p>
                          <div class="mt-1">
                            <v-chip
                              class="d-inline custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
                              color="cyan"
                              text-color="white"
                              label
                              small
                            >
                              <template v-if="data.is_filled"
                                >Submitted</template
                              >

                              <template v-else> Not Submitted Yet </template>
                            </v-chip>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'is_filled'">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                v-bind="attrs"
                                v-on="on"
                                class="mx-2 d-inline custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
                                color="cyan"
                                text-color="white"
                                label
                                small
                              >
                                <template v-if="data.is_filled"
                                  >Submitted</template
                                >

                                <template v-else> Not Submitted Yet </template>
                              </v-chip>
                            </template>
                            <span>Status</span>
                          </v-tooltip>
                        </template>

                        <template v-else-if="cols.field == 'report_details'">
                          <p class="m-0 custom-nowrap-ellipsis">
                            <template v-if="data && data.report_details">{{
                              data.report_details
                            }}</template>
                            <!-- <template v-if="data.visit && data.visit.property && data.visit.property.unit_no">-{{
                                data.visit.property.unit_no
                            }}</template>
                            <template v-if="data.visit && data.visit.barcode">-{{
                                data.visit.barcode
                            }}</template>
                            <template v-if="data.service_barcode ">-{{
                                data.service_barcode
                            }}</template>
                            <template v-if="data.added_at ">-{{
                                getHumanDate(data.added_at)
                            }}</template> -->
                          </p>
                        </template>

                        <template v-else-if="cols.field == 'added_at' && false">
                          <v-chip
                            v-if="data.display_name"
                            class="mx-2 my-1 custom-grey-border"
                            color="blue-grey darken-1"
                            text-color="white"
                          >
                            <v-avatar left>
                              <v-icon>mdi-account-circle</v-icon>
                            </v-avatar>
                            <p
                              class="m-0 custom-nowrap-ellipsis text-capitalize"
                            >
                              {{ data.display_name }}
                            </p>
                          </v-chip>
                          <TableActivity
                            v-if="!lodash.isEmpty(data.display_name)"
                            :data="data"
                          >
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>

                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatDateStartTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>

                        <template v-else-if="cols.field == 'added_at'">
                          <div>
                            <v-chip
                              class="mx-2 my-1 custom-grey-border"
                              color="blue-grey darken-1"
                              text-color="white"
                            >
                              <v-avatar left>
                                <v-icon>mdi-account-circle</v-icon>
                              </v-avatar>
                              <p
                                class="m-0 custom-nowrap-ellipsis text-capitalize"
                              >
                                <slot name="display_name">
                                  {{ data.display_name }}</slot
                                >
                              </p>
                            </v-chip>
                          </div>
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  class="mx-2 my-1 custom-grey-border"
                                  color="blue-grey darken-1"
                                  text-color="white"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-avatar left>
                                    <v-icon>mdi-clock-outline</v-icon>
                                  </v-avatar>
                                  <p class="m-0 custom-nowrap-ellipsis">
                                    <slot name="format_date_time">
                                      {{ data.created_at }}</slot
                                    >
                                  </p>
                                </v-chip>
                              </template>
                              <p class="m-0 custom-nowrap-ellipsis">
                                {{ formatDateTime(data.added_at) }}
                              </p>
                            </v-tooltip>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'download'">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :disabled="!data.view_pdf_url"
                                color="cyan"
                                v-on:click.stop.prevent="
                                  downloadFile(data.view_pdf_url)
                                "
                                icon
                              >
                                <v-icon>mdi-file-pdf-box</v-icon>
                              </v-btn>
                            </template>
                            <span>Download PDF</span>
                          </v-tooltip>

                          <v-tooltip
                            v-if="data.c_pdf_url"
                            top
                            content-class="custom-top-tooltip"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :disabled="!data.c_pdf_url"
                                color="cyan"
                                v-on:click.stop.prevent="
                                  downloadFile(data.c_pdf_url)
                                "
                                icon
                              >
                                <v-icon>mdi-file-pdf-box</v-icon>
                              </v-btn>
                            </template>
                            <span>Download Customer Signed PDF</span>
                          </v-tooltip>
                        </template>

                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="9" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no service at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Service</template>

          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFilterLabel(field, 'Service')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>

          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>

        <template v-if="getPermission('service:create')">
          <Dialog :commonDialog="importDialog">
            <template v-slot:title>Import Service</template>

            <template v-slot:body>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 90vh; position: relative"
              >
                <v-container fluid class="pt-0 custom-search-filter">
                  <v-row>
                    <template>
                      <a href="./../Sample/services.xlsx" download>
                        <v-btn
                          :disabled="dataLoading"
                          class="mx-2 custom-grey-border custom-bold-button"
                          color="cyan white--text"
                        >
                          Download Sample
                        </v-btn></a
                      >
                    </template>
                  </v-row>
                  <v-row>
                    <template>
                      <div class="container">
                        <form enctype="multipart/form-data" method="POST">
                          <div class="large-4 medium-4 small-4 cell">
                            <label>
                              <input
                                type="file"
                                id="file"
                                ref="file"
                                v-on:change="handleFileUpload()"
                              />
                            </label>
                          </div>
                        </form>
                      </div>
                    </template>
                  </v-row>
                </v-container>
              </perfect-scrollbar>
            </template>

            <template v-slot:action>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="importRows"
              >
                Import
              </v-btn>
              <v-btn
                :disabled="dataLoading"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="importDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </template>
      </template>
    </ListingTemplate>
    <Dialog :common-dialog="show_voice_notes">
      <template v-slot:title
        >Voice Notes ({{ voice_notes_list.length }})
        <!-- ({{ enquiry_barcode }}) :- Quotations --></template
      >

      <template v-slot:body>
        <v-container fluid class="py-0">
          <v-row>
            <v-col md="12" class="pt-0 custom-listing-table">
              <template>
                <table width="100%" class="white-background">
                  <thead>
                    <tr class="custom-border-bottom">
                      <td class="font-size-16 font-weight-700">S/N</td>
                      <td class="font-size-16 font-weight-700">Note</td>
                      <td class="font-size-16 font-weight-700">Added BY</td>
                      <td class="font-size-16 font-weight-700">Added At</td>
                    </tr>
                  </thead>
                  <!--   <tbody class="custom-border-bottom"> -->
                  <template v-for="(row, EIndex) in voice_notes_list">
                    <tr :key="EIndex" class="custom-border-bottom">
                      <td class="simple-table-td">{{ EIndex + 1 }}</td>
                      <td class="simple-table-td" align="left">
                        <!--   <div class="d-flex">
                                <div>02/02/2014 03:PM </div>
                                <v-spacer></v-spacer>
                                <div class="font-weight-700">  Mr. John Deo</div>
                            </div> -->
                        <audio
                          type="audio/mp3"
                          color="cyan"
                          controls
                          :src="row.file.url"
                        ></audio>
                      </td>
                      <td class="simple-table-td">
                        <span class="font-weight-700 text-capitalize">
                          {{ row.engineer_name }}
                        </span>
                      </td>
                      <td class="simple-table-td">
                        <v-chip
                          color="#24326d"
                          small
                          outlined
                          text-color=""
                          label
                        >
                          {{ formatDateStartTime(row.added_at) }}</v-chip
                        >
                      </td>
                    </tr>
                  </template>

                  <!--  </tbody> -->
                </table>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="show_voice_notes = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </div>
  <!--end::Service Listing-->
</template>

<script>
import moment from "moment-timezone";
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import { saveData } from "@/core/services/local.service";
import ListingMixin from "@/core/plugins/listing-mixin";
import ServiceMixin from "@/core/lib/service/service.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { DOWNLOAD, POST, PATCH } from "@/core/services/store/request.module";
import { saveAs } from "file-saver";
import { QUERY } from "@/core/services/store/request.module";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  mixins: [CommonMixin, ListingMixin, ServiceMixin],
  name: "service-list",
  data() {
    return {
      exportLoading: false,
      pageModule: "report-listing",
      routeAPI: "report",
      routeName: "service-form",
      routeDetailName: "service.detail",
      pageLoading: false,
      status: "all",
      file: "",
      importDialog: false,
      statusList: [],
      visitList: [],
      show_voice_notes: false,
      dates: [],
      voice_notes_list: [],
      customerList: [],
      engineerList: [],
      filter_engineer: null,
      customerLoading: false,
      engineerLoading: false,
      filter_customer: null,
      filter_visit: null,
      internal: {
        type: Boolean,
        default: false,
      },
      engineerId: {
        type: Number,
        default: 0,
      },
      customThead: [],
      allowedSearchFields: [
        "barcode",
        "company_name",
        "person_display_name",
        "unit_no",
        "street_1",
        "street_2",
        "zip_code",
        "city_name",
        "state_name",
        "country_name",
      ],
      moreActions: [
        /*{
            title: "Import Service(s)",
            action: "import_items",
            divider: true,
            icon: "mdi-database-import",
          },
          {
            title: "Export Service(s)",
            action: "export_items",
            divider: false,
            icon: "mdi-database-export",
          },*/
        /*{
            title: "Export Current View",
            action: "export_current_view",
            divider: true,
            icon: "mdi-database-export",
          },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "MARK ALL AS ACTIVE",
          icon: "mdi-check-all",
          status: 1,
          action: "active",
        },
        {
          title: "MARK ALL AS IN-ACTIVE",
          icon: "mdi-check-all",
          status: 0,
          action: "inactive",
        },
        {
          title: "Download Pdf",
          icon: "mdi-check-all",
          status: 2,
          action: "inactive",
        },
      ],
    };
  },
  components: {
    draggable,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
  },
  methods: {
    formatDateStartTime(row) {
      return moment(row).format("DD/MM/YYYY hh:mm A");
    },
    showNotes(data) {
      this.show_voice_notes = true;
      this.voice_notes_list = data.voice_notes;
    },
    getBarcode(service) {
      let barcodeStr = service.visit_barcode.split("-");
      barcodeStr = barcodeStr[0];
      let perent = service.visit_barcode.replace(barcodeStr, "S");
      /*   return perent + "-" + service.service_barcode; */
      return perent + "-01" + "-" + service.service_barcode;
    },
    getBarcodeBytrip(service) {
      let barcodeStr = service.visit_barcode.split("-");
      barcodeStr = barcodeStr[0];
      let perent = service.visit_barcode.replace(barcodeStr, "S");
      return perent + "-" + service.trip_code + "-" + service.service_barcode;
    },
    getCustomerList() {
      const _this = this;

      if (_this.customerLoading) {
        return false;
      }

      _this
        .getCustomers()
        .then((customer) => {
          _this.customerList = customer;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          /*    _this.getPropertyList(); */
        });
    },
    getCustomers() {
      const _this = this;
      _this.customerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "customer-list",
              data: { entity: _this.entityObject.id, all: true },
            })
            .then(({ data }) => {
              saveData("_btacl_tmp", data);
              resolve(data);
            })
            .catch((error) => {
              _this.customerLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.customerLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.customerLoading = false;
          reject(error);
        }
      });
    },
    getEngineers() {
      const _this = this;
      _this.engineerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "engineer-list",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              _this.engineerLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.engineerLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.engineerLoading = false;
          reject(error);
        }
      });
    },
    getEngineerList() {
      const _this = this;

      _this
        .getEngineers()
        .then((engineer) => {
          _this.engineerList = engineer;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    downloadFile(url, prepend_url) {
      if (!url) {
        return false;
      }
      if (prepend_url) {
        url = `https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/${url}`;
      }
      window.open(url, "_blank");
    },
    clearFilter(type) {
      if (type == "dates") {
        this.dates = null;
      }
      this.getVisits();
    },
    getVisits() {
      const _this = this;

      if (_this.pageLoading) {
        return false;
      }

      let filter = {
        daterange: _this.dates,
        customer: _this.filter_customer,
        visit_id: _this.filter_visit,
      };
      let status = "all";
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }

      _this.rowData = [];

      _this.pageLoading = true;

      let current_page = _this.currentPage;
      let per_page = _this.perPage;

      _this
        .getVisitList({ filter, current_page, per_page, status })
        .then((data) => {
          _this.rowData = data.rows;

          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getVisitList(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "report",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    calculateTotalDuration(visit_date) {
      if (moment(visit_date).isValid()) {
        let a = moment(visit_date);
        let b = moment();

        let totalDuration = moment.duration(a.diff(b));
        if (b.isAfter(a)) {
          totalDuration = moment.duration(b.diff(a));
        }

        let result = [];

        let years = totalDuration.get("years");
        if (years > 0) {
          if (years == 1) {
            result.push(years + " Year");
          } else {
            result.push(years + " Years");
          }
        }

        let months = totalDuration.get("months");
        if (months > 0) {
          if (months == 1) {
            result.push(months + " Month");
          } else {
            result.push(months + " Months");
          }
        }

        let days = totalDuration.get("days");
        if (days > 0) {
          if (days == 1) {
            result.push(days + " Day");
          } else {
            result.push(days + " Days");
          }
        }

        let hours = totalDuration.get("hours");
        if (hours > 0) {
          if (hours == 1) {
            result.push(hours + " Hour");
          } else {
            result.push(hours + " Hours");
          }
        }

        let minutes = totalDuration.get("minutes");
        if (minutes > 0) {
          if (minutes == 1) {
            result.push(minutes + " Minute");
          } else {
            result.push(minutes + " Minutes");
          }
        }

        return result.join(" ");
      }
    },

    getHumanDate: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatetimedata(row) {
      //console.log(row,"row");
      return moment(row, "HH:mm:ss").format("hh:mm A");
    },
    getVisitLists(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "visits",
              data,
            })
            .then(({ data }) => {
              resolve(data);
              _this.visitList = data.rows;
              console.log(_this.visitList);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    productBulkAction(status) {
      const _this = this;
      console.log(status);
      if (status == 2) {
        _this.$store
          .dispatch(PATCH, {
            url: "report/zip",
            data: {
              status,
              products: _this.selectedRows,
            },
          })
          .then((data) => {
            console.log(data);
            window.open(data, "_blank");
          })

          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.selectedRows = [];
            _this.getRows();
            _this.importDialog = false;
          });
      } else {
        _this.$store
          .dispatch(PATCH, {
            url: "product",
            data: {
              status,
              products: _this.selectedRows,
            },
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.selectedRows = [];
            _this.getRows();
            _this.importDialog = false;
          });
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkServicesExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("service");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    importRows() {
      // const _this = this;
      const file = this.$refs.file.files[0];
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      data.append("excel", file);
      const _this = this;
      _this.$store
        .dispatch(POST, {
          url: "services/import",
          data: data,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
          _this.importDialog = false;
        });
    },

    bulkServicesExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "services/export",
        })
        .then(({ data }) => {
          saveAs(data, "services-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Service Form",
      },
    ]);
    _this.getCustomerList();
    _this.getEngineerList();
    /*  _this.getVisitLists(); */

    if (this.getPermission("service:create") == false) {
      let index = this.lodash.findIndex(this.moreActions, {
        action: "import_items",
      });
      if (index >= 0) {
        this.moreActions.splice(index, 1);
      }
    }
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    // _this.defaultColDefs = [

    //   {
    //     headerName: "#",
    //     field: "barcode",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Timesheet #",
    //     field: "service_info",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 2,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Service Form",
    //     field: "price",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Created By",
    //     child: "created_at",
    //     field: "added_at",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 4,
    //     width: "170px",
    //   },

    //   {
    //     headerName: "Status",
    //     field: "is_filled",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 5,
    //     width: "170px",
    //   },
    //   {
    //     headerName: "Report Details",
    //     field: "report_details",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Download",
    //     child: "download",
    //     field: "download",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 6,
    //     width: "170px",
    //   },
    // ];

    // const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
    //   visible: true,
    // });

    // _this.defaultColShow = defaultColDefs.map((col) => col.field);

    /*  _this.status = _this.$route.query.status || _this.status; */

    // _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
  computed: {
    /*  selectedAlls: {
      set(param) {
        this.selectedRows = [];
        if (param && this.rowData.length) {
          for (let i = this.rowData.length - 1; i >= 0; i--) {
            this.selectedRows.push(this.rowData[i].service_form_id);
          }
        }
      },
      get() {
        let totalItems = this.rowData.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    }, */
  },
};
</script>

<style scoped>
/* Example CSS to change the color of audio player controls */
audio::-webkit-media-controls-panel {
  background-color: lightgray;
  /* Change the background color */
  color: #fff;
  /* Change the text color */
}
</style>

<template>
  <div class="ctx-job-create px-8 bg-white quotation-create-page">
    <v-form
      ref="createTicketForm"
      v-model="formValid"
      lazy-validation
      v-on:submit.stop.prevent="updateOrCreate()"
    >
      <div class="py-3">
        <div class="form-action">
          <div
            class="form-title d-flex"
            :class="{ 'pointer-events-none': renew_pm }"
          >
            Preventive Maintanance For
            <div
              v-if="vCustomerId"
              class="ml-3 form-title-link cursor-pointer text-h5"
              v-on:click="openDialog('customer')"
            >
              {{ vCustomer.display_name }}
            </div>
            <template v-else>
              <div
                class="ml-3 form-title-link cursor-pointer text-h5"
                v-on:click="openDialog('customer')"
              >
                Select a Customer
                <v-icon large color="cyan">mdi-account-check-outline</v-icon>
              </div>
              <div class="mx-3">OR</div>
              <div
                class="form-title-link cursor-pointer cyan--text text--darken-4 text-h5"
                v-on:click="createCustomerDialog()"
              >
                Create a new Customer
                <v-icon large color="cyan darken-4"
                  >mdi-plus-circle-outline</v-icon
                >
              </div>
            </template>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-chip
              v-if="renewPmBarcode"
              label
              color="chip-custom-blue"
              outlined
              class="text-white p-3 mr-4"
              style="font-size: 15px !important; font-weight: 600"
            >
              <template>
                {{ renewPmBarcode }}
              </template>
            </v-chip>
            <v-btn
              :disabled="prventiveLoading"
              v-on:click="goBack()"
              class="custom-bold-button custom-grey-border"
              depressed
              >Cancel</v-btn
            >
            <v-btn
              class="custom-bold-button ml-4"
              depressed
              :disabled="prventiveLoading"
              :loading="prventiveLoading"
              v-on:click="updateOrCreate()"
              color="cyan white--text"
              >Save</v-btn
            >
          </div>
        </div>
      </div>
      <v-row>
        <v-col md="7">
          <label class="ml-1 required" :for="`ctx-job-title-${uniqueId}`"
            >Title</label
          >
          <TextField
            id="job-title"
            dense
            filled
            placeholder="Title"
            solo
            flat
            counter="50"
            v-model="jobCreate.title"
            color="cyan"
            :maxlength="250"
            :rules="[validateRules.required(jobCreate.title, 'Title')]"
          />

          <label class="ml-1" :for="`ctx-job-description-${uniqueId}`"
            >Description</label
          >
          <TextAreaField
            auto-grow
            dense
            filled
            color="cyan"
            v-model="jobCreate.description"
            placeholder="Description"
            solo
            flat
            row-height="25"
            counter="250"
          />
        </v-col>
        <v-col md="5">
          <table width="100%">
            <tr>
              <td width="200px">
                <label :for="`ctx-job-visit-number-${uniqueId}`" class="mt-3"
                  >PM Number</label
                >
              </td>
              <td>
                <v-text-field
                  :id="`ctx-job-visit-number-${uniqueId}`"
                  dense
                  filled
                  class="font-weight-700 mt-4"
                  :disabled="prventiveLoading"
                  readonly
                  :value="preventiveOption.barcode"
                  placeholder="PM Number"
                  solo
                  flat
                  color="cyan"
                />
              </td>
            </tr>
            <tr>
              <td width="200px">
                <label :for="`ctx-job-amount-to-be-collected-${uniqueId}`"
                  >Cost</label
                >
              </td>
              <td>
                <v-text-field
                  :id="`ctx-job-amount-to-be-collected-${uniqueId}`"
                  dense
                  filled
                  :disabled="prventiveLoading"
                  placeholder="Cost"
                  solo
                  flat
                  type="number"
                  v-model="jobCreate.collected_amount"
                  v-on:keypress="
                    limitDecimal($event, jobCreate.collected_amount)
                  "
                  color="cyan"
                />
              </td>
            </tr>
            <tr v-if="false">
              <td width="200px">
                <label :for="`ctx-job-amount-to-be-collected-${uniqueId}`"
                  >Visit Create</label
                >
              </td>
              <td>
                <span
                  class="font-weight-600 d-flex"
                  style="margin-top: -3px !important"
                >
                  <v-checkbox
                    :disabled="
                      preventiveEditData?.preventive?.visit_create_flag
                    "
                    color="cyan"
                    v-model="jobCreate.visit_create_flag"
                    @change="
                      updateState({
                        key: 'visit_create_flag',
                        value: jobCreate.visit_create_flag,
                      })
                    "
                  ></v-checkbox>
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="1">
                <label
                  class="mb-0 font-weight-700"
                  :for="`ctx-job-contact-details-${uniqueId}`"
                  >Contact Details</label
                >
                <v-icon
                  v-if="!renew_pm && vCustomerId"
                  :disabled="prventiveLoading"
                  v-on:click="contactDialog = true"
                  class="mx-4"
                  small
                  color="cyan"
                >
                  mdi-pencil
                </v-icon>
                <template v-if="vCustomerId">
                  <p class="mb-1">
                    <template v-if="vPropertyContact?.display_name"
                      >{{ vPropertyContact?.display_name }}
                    </template>
                    <em v-else class="text-muted"> no display name</em>
                  </p>
                  <p class="mb-1">
                    <template v-if="vPropertyContact?.primary_phone"
                      >{{ vPropertyContact?.primary_phone }}
                    </template>
                    <em v-else class="text-muted">no phone number</em>
                  </p>
                  <p class="mb-1">
                    <template v-if="vPropertyContact?.primary_email"
                      >{{ vPropertyContact?.primary_email }}
                    </template>
                    <em v-else class="text-muted">no email </em>
                  </p>
                </template>

                <p v-else class="mb-0 red--text">Please select customer</p>
              </td>
              <td colspan="1">
                <label
                  class="mb-0 font-weight-700"
                  :for="`ctx-job-service-location-${uniqueId}`"
                >
                  Site Location
                  <v-icon
                    v-if="!renew_pm && vCustomerId"
                    :disabled="prventiveLoading"
                    v-on:click="propertyDialog = true"
                    class="mx-4"
                    small
                    color="cyan"
                  >
                    mdi-pencil
                  </v-icon>
                  <!--  <v-icon small color="cyan">mdi-history</v-icon> -->
                </label>
                <template v-if="vProperty?.id">
                  <span v-if="vProperty && vProperty.property_address">
                    <!--   <v-icon small>mdi-map-marker</v-icon>--></span
                  >
                  <p
                    class="mr-2"
                    v-if="vProperty && vProperty.property_address"
                  >
                    {{ vProperty.street_1 }},
                    <br v-if="vProperty.street_2 || vProperty.unit_no" />
                    <template v-if="vProperty.street_2">
                      {{ vProperty.street_2 }},
                    </template>
                    <template v-if="vProperty.unit_no">
                      {{ vProperty.unit_no }},
                    </template>
                    <br />
                    {{ vProperty.country }}
                    <template
                      v-if="vProperty && vProperty.zip_code != '000000'"
                    >
                      , {{ vProperty.zip_code }}
                    </template>
                  </p>
                </template>
                <p v-else class="mb-0 red--text">Please select customer</p>
              </td>
            </tr>
            <!--   <tr>
              <td colspan="2">
                <label
                  class="mb-0 font-weight-700"
                  :for="`ctx-job-contact-details-${uniqueId}`"
                  >Contact Details</label
                >
                 <p class="mb-1">{{ vPropertyContact?.display_name }}</p>
                <p class="mb-1">{{ vPropertyContact?.primary_phone }}</p>
                <p class="mb-1">{{ vPropertyContact?.primary_email }}</p>
              </td>
            </tr>
            <tr v-if="vPropertyContactId">
              <td colspan="2">
                <p class="mb-1">{{ vPropertyContact?.display_name }}</p>
                <p class="mb-1">{{ vPropertyContact?.primary_phone }}</p>
                <p class="mb-1">{{ vPropertyContact?.primary_email }}</p>
              </td>
            </tr> -->
            <!--  <tr v-else-if="vCustomerId">
              <td colspan="2">
                <p class="mb-0 red--text">Please select contact person</p>
              </td>
            </tr>
            <tr v-else>
              <td colspan="2">
                <p class="mb-0 red--text">Please select customer</p>
              </td>
            </tr> -->
            <!--  <tr>
              <td>
                <label :for="`ctx-job-email-notification-${uniqueId}`"
                  >Email Notification</label
                >
              </td>
              <td>
                <v-switch
                  v-model="jobCreate.email_notify"
                  :true-value="1"
                  :false-value="0"
                  :disabled="prventiveLoading"
                  class="m-0 p-0"
                  color="cyan"
                  dense
                  inset
                />
              </td>
            </tr> -->
          </table>
        </v-col>
        <v-col cols="12" class="job-schedule" ref="engineer">
          <!-- <template
            v-if="
              !preventiveEditData?.preventive?.visit_create_flag &&
              !preventiveEditData?.preventive?.id
            "
          > -->
          <template>
            <div style="border: 1px solid lightgray !important">
              <div
                class="py-3 grey lighten-4"
                @click="show_recurring_job = !show_recurring_job"
              >
                <div class="d-flex align-start">
                  <div class="px-3">
                    <v-icon>{{
                      !show_recurring_job
                        ? "mdi-chevron-down"
                        : "mdi-chevron-up"
                    }}</v-icon>
                  </div>
                  <div>
                    <h3 class="text-capitalize color-custom-blue">
                      Preventive Maintenace Schedule
                    </h3>
                    <!--   <p class="m-0 text-capitalize">
                        A contract job with repeating visits
                      </p> -->
                  </div>
                </div>
              </div>
              <v-expand-transition>
                <div class="px-3" v-show="show_recurring_job">
                  <RecurringSchedule :key="`job-recurring-schedule`" />
                </div>
              </v-expand-transition>
            </div>
          </template>
        </v-col>
        <v-col cols="12" ref="lineItem">
          <div style="border: 1px solid lightgray !important">
            <div
              class="py-3 grey lighten-4"
              @click="show_line_items = !show_line_items"
            >
              <div class="d-flex align-start">
                <div class="px-3">
                  <v-icon>{{
                    !show_line_items ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}</v-icon>
                </div>
                <div>
                  <h3 class="text-capitalize color-custom-blue">Line Items</h3>
                </div>
              </div>
            </div>
            <v-expand-transition>
              <div class="px-3 py-2" v-show="show_line_items">
                <LineItem
                  ref="lineItemRef"
                  is-job
                  is-preventive
                  :related-type="7"
                  :related-detail="preventiveEditData.preventive"
                />
                <LineItem v-if="false" :key="`job-line-item-${uniqueId}`" />
              </div>
            </v-expand-transition>
          </div>
        </v-col>
        <v-col cols="12" ref="attachments">
          <div style="border: 1px solid lightgray !important">
            <div
              class="py-3 grey lighten-4"
              @click="show_attachments = !show_attachments"
            >
              <div class="d-flex align-start">
                <div class="px-3">
                  <v-icon>{{
                    !show_attachments ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}</v-icon>
                </div>
                <div>
                  <h3 class="text-capitalize color-custom-blue">Attachments</h3>
                </div>
              </div>
            </div>
            <v-expand-transition>
              <div class="px-3 py-2" v-show="show_attachments">
                <file-upload v-model="jobCreate.attachments" :maxLimit="5">
                </file-upload>
              </div>
            </v-expand-transition>
          </div>
        </v-col>
        <v-col md="12" class="job-schedule pb-0" v-if="false">
          <v-tabs
            v-model="jobCreate.schedule_tab"
            background-color="transparent"
            centered
            :disabled="prventiveLoading"
            color="cyan"
            icons-and-text
            class="mb-3"
          >
            <v-tab
              :disabled="prventiveLoading"
              href="#one-off"
              style="min-width: 390px"
            >
              <div class="custom-tab-title d-flex">
                <div class="custom-tab-icon pr-2">
                  <v-icon left large color="gray">mdi-calendar</v-icon>
                </div>
                <div class="text-left">
                  <h3 class="text-uppercase font-weight-700">One-Off Job</h3>
                  <p class="m-0 text-capitalize">
                    A one time job with one or more visits
                  </p>
                </div>
              </div>
            </v-tab>
            <v-tab
              :disabled="prventiveLoading"
              href="#recurring"
              style="min-width: 390px"
            >
              <div class="custom-tab-title d-flex">
                <div class="custom-tab-icon pr-2">
                  <v-icon left large color="gray">mdi-calendar-multiple</v-icon>
                </div>
                <div class="text-left">
                  <h3 class="text-uppercase font-weight-700">Recurring Job</h3>
                  <p class="m-0 text-capitalize">
                    A contract job with repeating visits
                  </p>
                </div>
              </div>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="jobCreate.schedule_tab"
            class="remvoe-overflow"
          >
            <!--  <v-tab-item value="one-off"> </v-tab-item> -->
            <v-tab-item value="recurring"> </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col md="12" v-if="false">
          <v-expansion-panels :disabled="prventiveLoading" flat tile multiple>
            <v-expansion-panel
              v-if="jobCreate.schedule_tab == 'recurring'"
              class="mb-4 custom-border"
            >
              <v-expansion-panel-header color="grey lighten-4">
                <div class="my-3">
                  <h3
                    class="text-capitalize color-custom-blue text-uppercase font-weight-700"
                  >
                    Recurring Schedule
                  </h3>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <RecurringSchedule
                    :key="`job-recurring-schedule-${scheduleKey}`"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <div class="border">
              <div
                class="py-3 grey lighten-4"
                @click="show_line_items = !show_line_items"
              >
                <div class="d-flex align-start">
                  <div class="px-3">
                    <v-icon>{{
                      !show_line_items ? "mdi-chevron-down" : "mdi-chevron-up"
                    }}</v-icon>
                  </div>
                  <div>
                    <h3 class="text-capitalize color-custom-blue">
                      Line Items
                    </h3>
                  </div>
                </div>
              </div>
              <v-expand-transition>
                <div class="px-3 py-2">
                  <LineItem
                    is-job
                    :related-type="7"
                    :related-detail="preventiveEditData.preventive"
                    ref="lineItemRef"
                  />
                  <LineItem v-if="false" :key="`job-line-item-${uniqueId}`" />
                </div>
              </v-expand-transition>
            </div>
            <v-expansion-panel class="mb-4 custom-border" v-if="false">
              <v-expansion-panel-header color="grey lighten-4">
                <div class="my-3">
                  <h3
                    class="text-capitalize color-custom-blue text-uppercase font-weight-700"
                  >
                    Line Items
                    <!-- ({{ vSelectedLineItem.length }}) -->
                  </h3>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <div>
                  <LineItem
                    is-job
                    :related-type="7"
                    :related-detail="preventiveEditData.preventive"
                  />
                  <LineItem v-if="false" :key="`job-line-item-${uniqueId}`" />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="mb-4 custom-border">
              <v-expansion-panel-header color="grey lighten-4">
                <div class="my-3">
                  <h3
                    class="text-capitalize color-custom-blue text-uppercase font-weight-700"
                  >
                    Attachments
                  </h3>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="pt-4">
                  <v-row>
                    <v-col md="12">
                      <file-upload v-model="jobCreate.attachments">
                      </file-upload>
                    </v-col>
                  </v-row>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-form>
    <!--  <CustomerSelect
      :key="`job-customer-select-${dailogKey}`"
      :dialog="customerDialog"
      v-on:close="customerDialog = false"
    /> -->

    <CustomerSelect :dialog="reCustomerDialog" @close="closeCustomerDialog" />
    <customer-create :key="`pm-customer-create-${dialog_key}`" />

    <PersonSelect
      :key="`job-person-select-${dailogKey}`"
      :dialog="contactDialog"
      v-on:close="contactDialog = false"
      :type="'property'"
    />

    <AddressSelect
      :key="`job-address-select-${dailogKey}`"
      :dialog="propertyDialog"
      v-on:close="propertyDialog = false"
      type="property"
      label="Site Location"
      placeholder="Site Location"
    />
    <Dialog :common-dialog="confirmDialog">
      <template v-slot:title>Confirmation</template>
      <template v-slot:body>
        <v-container fluid class="py-0">
          <div
            v-if="updateScheduleChanged"
            class="p-4 mb-4 font-size-17 font-weight-400 warning-background"
          >
            <p
              class="mb-0 d-flex align-center font-weight-600 red--text text--darken-3"
            >
              <v-icon color="red darken-3" class="mr-2"
                >mdi-alert-circle-outline</v-icon
              >
              Attention! Schedule has been Changed.
            </p>
          </div>

          <div
            v-if="updateTeamChanged"
            class="p-4 mb-4 font-size-17 font-weight-400 warning-background"
          >
            <p
              class="mb-0 d-flex align-center font-weight-600 red--text text--darken-3"
            >
              <v-icon color="red darken-3" class="mr-2"
                >mdi-alert-circle-outline</v-icon
              >
              Attention! Team has been Changed.
            </p>
          </div>

          <v-radio-group v-model="updateTargetType" row>
            <v-radio
              color="cyan"
              label="Update the current visit only?"
              value="self"
            ></v-radio>
            <v-radio
              color="cyan"
              label="Update all upcoming visits?"
              value="upcomming"
            ></v-radio>
          </v-radio-group>

          <div
            v-if="updateTargetType == 'upcomming'"
            class="p-4 mt-4 font-size-16 font-weight-400 warn-warning-background"
          >
            <p
              class="mb-0 d-flex align-center font-weight-600 orange--text text--darken-4"
            >
              <v-icon color="orange darken-4" class="mr-2"
                >mdi-alert-circle-outline</v-icon
              >
              Note: The visits scheduled ahead will be changed with a new
              SCHEDULE and TEAM. Visit # will be updated accordingly.
            </p>
          </div>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          :disabled="prventiveLoading"
          v-on:click="
            scheduleChangeConfirmed = true;
            updateOrCreate();
          "
        >
          Continue
        </v-btn>
        <v-btn
          :disabled="prventiveLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="confirmDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ValidationMixin from "@/core/plugins/validation-mixin";

/* import OneOffScheduleNew from "@/view/components/Preventive/PreventiveOneOffSchedule.vue"; */
import RecurringSchedule from "@/view/components/Preventive/PreventiveRecurringSchedule.vue";
import LineItem from "@/view/pages/partials/Line-Item-V2.vue";
import CustomerSelect from "@/view/components/CustomerSelect.vue";
import AddressSelect from "@/view/components/AddressSelect.vue";
import PersonSelect from "@/view/components/PersonSelect.vue";
import FileUpload from "@/view/components/app-component/FileInput.vue";
import ObjectPath from "object-path";
import CustomerCreate from "@/view/components/CustomerCreateDialog.vue";

import {
  CLEAR_PREVENTIVE,
  GET_PREVENTIVE,
  GET_OPTION,
  SET_SCHEDULE_TYPE,
  CREATE_OR_UPDATE,
  SET_LOADING,
  UPDATE_SCHEDULE_STATE,
} from "@/core/services/store/preventive.module";
import TextAreaField from "@/view/components/app-component/TextAreaField.vue";
import {
  SET_VISIT_LINE_ITEM,
  /*  SET_VISIT_CALCULATION, */
} from "@/core/services/store/visit.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
/*   import ManageJob from "@/core/plugins/job.plugin"; */
import { UPLOAD } from "@/core/services/store/request.module";
import {
  /* SET_EDIT_MODE, */ CLOSE_DUP_DIALOG,
  SET_CUSTOMER,
  SET_PROPERTY,
  SET_PROPERTY_CONTACT,
  SET_BILLING,
  SET_BILLING_CONTACT,
  CLEAR_CUSTOMER,
  SET_DIALOG_STATUS,
  SET_CREATE_DIALOG_STATUS,
} from "@/core/services/store/customer.module";
import { QUERY } from "@/core/services/store/request.module";

export default {
  mixins: [ValidationMixin],
  name: "job-ctx-create",
  title: "Create Job",
  data() {
    return {
      renew_pm: 0,
      renewPmBarcode: null,
      confirmDialog: false,
      updateScheduleChanged: false,
      scheduleChangeConfirmed: false,
      updateTeamChanged: false,
      show_line_items: false,
      show_recurring_job: true,
      dialog_key: Number(new Date()),
      show_attachments: false,
      updateTargetType: "self",
      preventiveId: 0,
      /* visitId: 0, */
      duplicatePreventiveId: 0,
      /*   duplicateVisitId: 0, */
      uniqueId: Number(new Date()),
      scheduleKey: Number(new Date()),
      dailogKey: Number(new Date()),
      formValid: true,
      /* customerDialog: false, */
      contactDialog: false,
      propertyDialog: false,
      contactType: null,
      attachments: [
        {
          accepted_file: null,
          file_name: null,
          file_type: null,
        },
      ],
      isEditPage: false,
      isEditLoaded: false,
      jobCreate: {
        schedule_tab: "recurring",
        priority: 3,
        visit_create_flag: false,
        title: null,
        description: null,
        remark: null,
        barcode: null,
        collected_amount: null,
        term_conditions: null,
        client_remark: null,
        admin_remark: null,
        attachments: [],
      },
    };
  },
  watch: {
    "prventiveRecurringSchedule.dbEndDate"() {
      if (this.prventiveRecurringSchedule?.dbEndDate) {
        this.$store.dispatch(SET_VISIT_LINE_ITEM, []);
        this.$refs.lineItemRef.selected_line_items = [];
      }
    },
    "prventiveRecurringSchedule.start_date"() {
      if (this.prventiveRecurringSchedule?.start_date) {
        this.$store.dispatch(SET_VISIT_LINE_ITEM, []);
        this.$refs.lineItemRef.selected_line_items = [];
      }
    },
  },
  computed: {
    ...mapGetters([
      "defaultStartedAt",
      "defaultFinishedAt",
      "vDupDialog",
      "vDupVisit",
      "preventiveEditData",
      "preventiveOption",
      "reCustomerDialog",
      /*    "liCalculation", */
      "vCalculations",
      "vSelectedLineItem",
      "preventiveScheduleOutput",
      "oneOffSchedule",
      "prventiveRecurringSchedule",
      "assignedTeam",
      "prventiveLoading",
      "vCustomerId",
      "vPropertyId",
      "vPropertyContactId",
      "vBillingId",
      "vBillingContactId",
      "vCustomer",
      "vProperty",
      "vPropertyContact",
      "vBilling",
      "vBillingContact",
    ]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  components: {
    LineItem,
    RecurringSchedule,
    /*  OneOffScheduleNew, */
    FileUpload,
    CustomerSelect,
    "customer-create": CustomerCreate,
    AddressSelect,
    PersonSelect,
    TextAreaField,
  },
  methods: {
    closeCustomerDialog() {
      this.$store.commit(SET_DIALOG_STATUS, false);
    },
    openDialog(type) {
      this.$store.commit(SET_DIALOG_STATUS, false);
      if (type == "customer") {
        this.$store.commit(SET_DIALOG_STATUS, true);
      } else {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
      }
    },
    createCustomerDialog() {
      this.dialog_key = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },
    updateState(payload) {
      this.$nextTick(() => {
        this.$store.commit(UPDATE_SCHEDULE_STATE, payload);
      });
    },
    includeIntoRemark(type) {
      let remark = [];
      if (type == "all") {
        remark = this.vDupVisit.map((row) => row.barcode);
      }
      if (type == "selected") {
        remark = this.vDupVisit.filter((row) => row.status);
        if (!remark.length) {
          ErrorEventBus.$emit("update:error", "Select Atleast One Visit");
          return false;
        }
        remark = remark.map((row) => row.barcode);
      }
      if (remark.length) {
        this.jobCreate.remark = remark.join(", ");
      }
      this.closeDupDialog();
    },
    closeDupDialog() {
      for (let i = 0; i < this.vDupVisit.length; i++) {
        this.vDupVisit[i].status = false;
      }
      this.$store.commit(CLOSE_DUP_DIALOG);
    },
    dupCheckAll(param) {
      for (let i = 0; i < this.vDupVisit.length; i++) {
        this.vDupVisit[i].status = param;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.attachments.length; i++) {
          if (_this.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.attachments[i].accepted_file,
              _this.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    removeRow(target, index) {
      ObjectPath.del(this, `${target}.${index}`);
      this.$nextTick(() => {
        if (!this.attachments.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      this.attachments.push({
        accepted_file: null,
        file_name: null,
        file_type: null,
      });
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    updateFileName(index) {
      const file = this.attachments[index].accepted_file;
      if (file) {
        this.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.attachments[index].file_type = file.name.split(".").pop();
        this.addAttachmentRow();
      }
    },
    async updateOrCreate() {
      try {
        if (!this.vCustomerId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Customer.")
          );
          return false;
        }

        if (!this.vPropertyId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Site Location.")
          );
          return false;
        }

        if (!this.vPropertyContactId) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Please Select Contact Person.")
          );
          return false;
        }
        if (this.jobCreate.schedule_tab == "recurring") {
          if (!this.prventiveRecurringSchedule.message) {
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Oops... Dates are not available, Please select valid schedule.."
              )
            );
            return false;
          }
        }
        const allHeaders = this.vSelectedLineItem.every(
          (obj) => obj.type === "header"
        );
        if (
          !this.vSelectedLineItem ||
          !this.vSelectedLineItem.length ||
          allHeaders
        ) {
          ErrorEventBus.$emit("update:error", "Select Line Item");
          return false;
        }
        /*  if (!this.vSelectedLineItem || !this.vSelectedLineItem.length) {
          ErrorEventBus.$emit("update:error", "Select Line Item");
          return false;
        }
 */
        const validateStatus = this.$refs.createTicketForm.validate();

        const formErrors = this.validateForm(this.$refs.createTicketForm);

        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        if (!validateStatus) {
          return false;
        }

        this.$store.commit(SET_LOADING, true);

        const new_attachments = await this.uploadFiles();
        /*   console.log(this.vSelectedLineItem); */
        const request = {
          ...this.jobCreate,
          default_started_at: this.defaultStartedAt,
          default_finished_at: this.defaultFinishedAt,
          update_schedule_changed: +this.updateScheduleChanged,
          update_team_changed: +this.updateTeamChanged,
          update_target_type: this.updateTargetType,
          attachments: [...this.jobCreate.attachments, ...new_attachments],
          preventive: this.preventiveId,
          /*     visit: this.visitId, */
          customer: this.vCustomerId,
          property: this.vPropertyId,
          property_contact: this.vPropertyContactId,
          billing: this.vBillingId,
          billing_contact: this.vBillingContactId,
          calculation: this.vCalculations,
          "line-item": this.vSelectedLineItem,
          team: this.assignedTeam,
          clear_engineers: +!this.assignedTeam.length,
          oneoff:
            this.jobCreate.schedule_tab == "one-off" ? this.oneOffSchedule : {},
          recurring:
            this.jobCreate.schedule_tab == "recurring"
              ? this.prventiveRecurringSchedule
              : {},
          schedule:
            this.jobCreate.schedule_tab == "recurring"
              ? this.preventiveScheduleOutput
              : [],
          renewed_id: this.renew_pm ? this.renew_pm : 0,
        };

        this.$store.dispatch(CREATE_OR_UPDATE, request).then(() => {
          this.$router.replace({
            name: "admin.preventive-maintanance",
            query: { status: "all" },
          });
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit(SET_LOADING, false);
      }
    },
    updateScheduleType(param) {
      this.scheduleKey = Number(new Date());
      this.$store.commit(SET_SCHEDULE_TYPE, param);
    },
    getPreventive(id) {
      this.$store
        .dispatch(GET_PREVENTIVE, {
          preventive: id,
          /*  visit: this.visitId, */
        })
        .then(() => {
          this.isEditLoaded = true;

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Preventive", route: "preventive-maintanance" },
            { title: "Update" },
            { title: this.preventiveEditData?.preventive?.barcode },
          ]);

          this.jobCreate = {
            schedule_tab: "recurring",
            priority: this.preventiveEditData?.preventive?.priority,
            title: this.preventiveEditData?.preventive?.title,
            description: this.preventiveEditData?.preventive?.remark,
            visit_create_flag:
              this.preventiveEditData?.preventive?.visit_create_flag,
            remark: this.preventiveEditData?.preventive?.additional_remarks,
            barcode: this.preventiveEditData?.preventive?.barcode,
            collected_amount: this.preventiveEditData?.preventive?.cost,
            term_conditions:
              this.preventiveEditData?.preventive?.term_conditions,
            client_remark: this.preventiveEditData?.preventive?.client_remark,
            admin_remark: this.preventiveEditData?.preventive?.admin_remark,
            email_notify: this.preventiveEditData?.preventive?.notify_engineer,
            attachments: [],
          };
          this.updateState({
            key: "visit_create_flag",
            value: this.preventiveEditData?.preventive?.visit_create_flag,
          });
          if (!this.renew_pm) {
            this.preventiveOption.barcode =
              this.preventiveEditData?.preventive?.barcode;
          }
          if (this.renew_pm) {
            this.renewPmBarcode = this.preventiveEditData?.preventive?.barcode;
          }
        })
        .catch(() => {
          /*   this.goBack(); */
        })
        .finally(() => {
          //
        });
    },
    getCustomer(customerId, contactPersonId, propertyId) {
      this.$store
        .dispatch(QUERY, {
          url: `customer-with-relation/${customerId}`,
          data: {
            contact_person: contactPersonId,
            property: propertyId,
          },
        })
        .then((response) => {
          this.$store.commit(
            SET_CUSTOMER,
            ObjectPath.get(response, "data.customer", {})
          );
          this.$store.commit(
            SET_PROPERTY,
            ObjectPath.get(response, "data.property", {})
          );
          this.$store.commit(
            SET_PROPERTY_CONTACT,
            ObjectPath.get(response, "data.property_contact_person", {})
          );
          this.$store.commit(
            SET_BILLING,
            ObjectPath.get(response, "data.billing", {})
          );
          this.$store.commit(
            SET_BILLING_CONTACT,
            ObjectPath.get(response, "data.billing_contact_person", {})
          );
        })
        .catch(() => {
          this.goBack();
        })
        .finally(() => {
          //
        });
    },
    /* getDuplicateTicket() {
        this.$store
          .dispatch(GET_TICKET, { ticket: this.duplicateTicketId, visit: this.duplicateVisitId })
          .then(() => {
            this.jobCreate.schedule_tab = this.preventiveEditData?.ticket?.type == 2 ? "recurring" : "one-off";
            this.jobCreate.priority = this.preventiveEditData?.ticket?.priority;
            this.jobCreate.title = this.preventiveEditData?.ticket?.title;
            this.jobCreate.description = this.preventiveEditData?.ticket?.description;
            this.jobCreate.remark = this.preventiveEditData?.ticket?.additional_remarks;
            this.jobCreate.collected_amount = this.preventiveEditData?.ticket?.amount_to_be_collected;
            this.jobCreate.term_conditions = this.preventiveEditData?.ticket?.term_conditions;
            this.jobCreate.client_remark = this.preventiveEditData?.ticket?.client_remark;
            this.jobCreate.admin_remark = this.preventiveEditData?.ticket?.admin_remark;
            this.jobCreate.email_notify = this.preventiveEditData?.ticket?.notify_engineer;
            this.jobCreate.attachments = [];
          })
          .catch(() => {
            this.goBack();
          })
          .finally(() => {
            //
          });
      }, */
  },
  created() {
    this.preventiveId = Number(this.$route?.query?.preventive ?? 0);
    this.renew_pm = this.lodash.toSafeInteger(this.$route.query.renew_pm);
    this.visitId = Number(this.$route?.query?.visit ?? 0);
    this.duplicatePreventiveId = Number(
      this.$route?.query["duplicate-preventive"] ?? 0
    );
    this.duplicateVisitId = Number(this.$route?.query["duplicate-visit"] ?? 0);
  },
  destroyed() {
    this.$store.dispatch(CLEAR_CUSTOMER);
    this.$store.dispatch(CLEAR_PREVENTIVE);
  },
  beforeCreate() {
    this.$store.dispatch(CLEAR_CUSTOMER);
  },
  mounted() {
    this.$store.dispatch(GET_OPTION);

    if (this.duplicatePreventiveId && this.duplicateVisitId) {
      this.getDuplicateTicket();
    }

    const customerId = Number(this.$route?.query?.customer ?? 0);
    const contactPersonId = Number(this.$route?.query?.contact_person ?? 0);
    const propertyId = Number(this.$route?.query?.property ?? 0);

    if (customerId) {
      this.getCustomer(customerId, contactPersonId, propertyId);
    }
    if (this.renew_pm) {
      this.getPreventive(this.renew_pm);
    }
    if (this.preventiveId /* && this.visitId */) {
      this.getPreventive(this.preventiveId);
      this.isEditPage = true;
      /*     this.$store.commit(SET_EDIT_MODE, true); */
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Preventive Maintanance", route: "preventive-maintanance" },
        { title: "Update" },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Preventive Maintanance", route: "preventive-maintanance" },
        { title: "Create" },
      ]);
    }
  },
  /* computed:{
    this.prventiveRecurringSchedule?.dbEndDate
  }, */
};
</script>

<style lang="scss" scoped>
.quotation-create-page {
  border-top: solid 8px #f7941e;

  label {
    padding-left: 5px;
    font-weight: 600;
  }

  p {
    padding-left: 5px;
  }
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;

    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
